// @flow
import * as React from 'react'
import cn from 'classnames'
import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import { Store } from 'Store'
import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

type ImagePath = string;

type Props = {
    theme: {
        background: string,
        color: string,
        logo: ImagePath,
    }
}

const Footer = (props: Props) => {
    const { theme } = props
    const { state } = React.useContext(Store)

    const clientAddress = React.useMemo(() => {
        return state.campaign.client.address ? [
            state.campaign.client.address.line1,
            state.campaign.client.address.line2,
            [state.campaign.client.address.city, state.campaign.client.address.province, state.campaign.client.address.postalCode]
        ].filter(Boolean).map(i => Array.isArray(i) ? i.filter(Boolean).join(' ') : i).join(', ') : null
    }, [state.campaign.client.address])

    return (
        <div className={cn(styles.container, {
            [styles.withLogo]: theme.logo
        })} style={{
            backgroundColor: theme.background
        }}>
            {theme.logo && <img className={styles.logo} src={theme.logo} alt=''/>}

            <div>
                <p className={styles.disclaimer}>{clientAddress}&nbsp;</p>
                {state.campaign.contactInfo.phoneNumber || state.campaign.contactInfo.email
                    ? <p className={styles.disclaimer}>
                        {state.campaign.contactInfo.phoneNumber
                            ? <span>phone: <a href={`tel:${state.campaign.contactInfo.phoneNumber}`}>{state.campaign.contactInfo.phoneNumber}</a></span>
                            : null}
                        <span className={styles.divider} />
                        {state.campaign.contactInfo.email
                            ? <span>email: <a href={`mailto:${state.campaign.contactInfo.email}`}>{state.campaign.contactInfo.email}</a></span>
                            : null}
                    </p>
                    : null}
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Footer))
