// @flow
import React from 'react'
import {
    BodyLayout,
    LeftSide,
    RightSide
} from 'components/MainLayout'
import Landing from 'components/Landing'
import withTheme from 'hoc/withTheme'
import { Store } from 'Store'
import Modal from 'components/Modal'
import VideoPlayer from 'components/VideoPlayer'

import themes, { type Theme } from './themes/__supportedThemes'

import mask from 'themes/images/mask.png'

import styles from './styles.module.scss'

type Props = {
    history: any,
    location: any,
    match: any,
    theme: Theme
}

function LandingPage (props: Props) {
    const videoPlayer = React.useRef()
    const { state } = React.useContext(Store)
    const [ isModalOpen, setIsModalOpen ] = React.useState(false)

    const handleOpenModal = React.useCallback(() => {
        if (!state.campaign.videos.masthead) return alert('video not available')
        setIsModalOpen(true)
    }, [ state.campaign.videos.masthead ])

    const playVideo = React.useCallback(() => {
        videoPlayer.current.play()
    }, [])

    const handleClose = React.useEffect(() => {
        setIsModalOpen(false)
    }, [])

    return (
        <>
            <BodyLayout className={styles.container}>
                <LeftSide className={styles.imgContainer} style={{ backgroundImage: `url(${props.theme.sideDesktop})` }}>
                    {props.theme.playIcon && state.campaign.videos.masthead && (
                        <div
                            style={{ backgroundImage: `url(${props.theme.playIcon})` }}
                            className={styles.playIcon}
                            onClick={handleOpenModal}
                        />
                    )}
                </LeftSide>
                <RightSide style={{ backgroundColor: props.theme.backgroundColor }}>
                    <div className={styles.bgImg} style={{ '--left-side-image': `url(${props.theme.sideMobile})` }}>
                        {props.theme.playIcon && (
                            <div
                                style={{ backgroundImage: `url(${props.theme.playIcon})` }}
                                className={styles.playIcon}
                                onClick={handleOpenModal}
                            />
                        )}
                        <div className={styles.mask} style={{ backgroundImage: `url(${mask})` }}/>
                    </div>

                    <Landing
                        onSubmit={(data, done) => {
                            window.scrollTo(0, 0)
                            props.history.push(`${props.match.params.url}/step-1${props.location.search}`)
                        }}
                        initialValues={{}}
                    />
                </RightSide>
            </BodyLayout>

            {state.campaign.videos.masthead && (
                <Modal afterOpen={playVideo} isOpen={isModalOpen} onCancel={handleClose}>
                    <VideoPlayer src={state.campaign.videos.masthead} ref={videoPlayer}/>
                </Modal>
            )}
        </>
    )
}

export default withTheme(themes)(LandingPage)
