import React from 'react'
import { BodyLayout, LeftSide, RightSide } from 'components/MainLayout'

import styles from './styles.module.scss'
import { Store } from 'Store'
import { Redirect } from 'react-router-dom'

import Success from 'components/LeadGenForm/Success'

import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes.js'
import mask from 'themes/images/mask.png'

export default withTheme(supportedThemes)(({ history, location, match, theme }) => {
    const { state } = React.useContext(Store)
    if (!state.lead) {
        return <Redirect to={`/${match.params.url}${location.search}`} push />
    }
    return (
        <BodyLayout className={styles.container}>
            <LeftSide title="" className={styles.imgContainer} style={{
                backgroundImage: `url(${theme.sideDesktop})`
            }}>
            </LeftSide>
            <RightSide style={{
                backgroundColor: theme.backgroundColor
            }}>
                <div className={styles.bgImg} style={{ '--left-side-image': `url(${theme.sideMobile})` }}>
                    <div className={styles.mask} style={{ backgroundImage: `url(${mask})` }}/>
                </div>
                <Success state={state} />
            </RightSide>
        </BodyLayout>
    )
})
