// @flow
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Store } from 'Store'
import withTheme from 'hoc/withTheme'
import StepLayout from 'components/StepLayout'
import Countdown from 'components/Countdown'
import AbsoluteTime from 'components/AbsoluteTime'
import transDomain from './translations/index.translations'

import * as Form from 'components/Form'
import withForm from 'components/Form/Helpers/FormHOC'

import styles from './styles.module.scss'

import supportedThemes from './themes/__supportedThemes'
import Typography from '../Typography'
import Content from '../Content'

Landing.defaultProps = {
    date: new Date()
}

function Landing (props: Props) {
    const { theme } = props
    const { t } = useTranslation(transDomain)
    const { state } = React.useContext(Store)
    return (
        <StepLayout>
            <Typography isCentered>
                {theme.hasCountdown && (
                    <div className={styles.expiryText}>
                        <div className={styles.eventExpiry}>
                            <span style={{
                                color: theme.eventExpiryDateColor
                            }}>
                                {t('event_expiry')}&nbsp;<AbsoluteTime dateFormat='long' date={state.campaign.expiryDate}/>
                            </span>
                        </div>
                        <div className={styles.countdown} style={{ color: theme.eventExpiryCountdownColor }}>
                            <Countdown date={state.campaign.expiryDate}/>
                        </div>
                    </div>
                )}
                <Content contentKey={'masthead.heading'} wrapper={'h1'}>
                    {state.lead.fields.firstName}, thank you for being a part of their story.
                </Content>
                <Content className={styles.message} contentKey={'masthead.message'} wrapper={'p'}>
                    Support from alumni, community and corporate sponsors have provided countless opportunities for our students to thrive.
                </Content>
                <Form.Submit
                    isLoading={props.isLoading}
                    label={t(props.theme.buttonTransKey)}
                    style={{
                        background: theme.background,
                        color: theme.color,
                        marginTop: '1.62em'
                    }}
                />
            </Typography>
        </StepLayout>
    )
}

export default withForm()(withTheme(supportedThemes)(Landing))
