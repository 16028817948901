import React from 'react'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import cn from 'classnames'

function SwitchSelector (props) {
    return (
        <div className={styles.container} style={{
            backgroundColor: props.theme.backgroundColor,
        }}>
            <div className={styles.uidata}>
                {props.options.map(text => <div onClick={() => props.handleInputChange(text)} key={text} className={cn(styles.option, {
                    [styles.active]: props.selected === text,
                    [styles.fullColor]: props.theme.fullColor,
                    [styles.fullWidth]: props.options.length === 1,
                    [styles.borderLess]: props.theme.borderLess,
                })} style={{ color: props.theme.color, '--switch-selector-border-color': props.theme.color }}>
                    {props.trans ? props.trans(props.labelFormat.replace('%name%', text)) : text}
                </div>)}
            </div>
            <span className={styles.maskValidator}>
                <input required={props.required} value={props.selected} type="text"/>
            </span>
        </div>
    )
}

SwitchSelector.defaultProps = {
    options: [], // max length 2,
    selected: null // 0 xor 1
}

export default withTheme(supportedThemes)(SwitchSelector)
