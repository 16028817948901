// @flow
import * as React from 'react'
import Typography from 'components/Typography'

import cn from 'classnames'

import styles from './styles.module.scss'

import withTheme from 'hoc/withTheme'
import themes from './themes/__supportedThemes.js'

export const LeftSide = withTheme(themes)(function (props) {
    return (
        <div className={cn(styles.leftSide, {
            [styles.centerTitle]: props.centerTitle,
            [styles.fixedTitle]: props.fixedTitle,
            [styles.hasLightestTitle]: props.theme.hasLightestTitle,
        }, props.className)} style={props.style}>
            {props.children}

            {props.title && (
                <span className={styles.title}>
                    {props.title}
                </span>
            )}
        </div>
    )
})

export const RightSide = withTheme(themes)(function (props) {
    return (
        <div className={cn(styles.rightSide, props.className)} style={props.style}>
            <div>
                {props.children}
            </div>
        </div>
    )
})

export const BodyLayout = withTheme(themes)(function (props) {
    return (
        <div className={cn(styles.body, props.className)} style={props.style}>
            {props.children}
        </div>
    )
})

function MainLayout (props) {
    return (
        <Typography>
            <div
                className={cn(styles.container, props.className)}
                style={props.style}>
                {props.children}
            </div>
        </Typography>
    )
}

export default withTheme(themes)(MainLayout)
