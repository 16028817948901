import React from 'react'
import cn from 'classnames'

import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

function DonateOption (props) {
    return (
        <div className={cn(styles.donateBtn, props.className, {
            [styles['donateBtn--small']]: props.small,
            [styles.active]: props.active,
            [styles.hasBackground]: props.theme.hasBackground,
            [styles.borderLess]: props.theme.borderLess,
            [styles.fullColor]: props.theme.fullColor,
        })} onClick={props.onClick} style={{
            '--donate-option-color': props.theme.color,
            ...props.styleProp
        }}>
            {props.icon && <div className={styles.icon} style={{ backgroundImage: `url(${props.icon})` }}></div>}
            {props.title && <span className={cn({
                [styles.center]: props.center
            })}>{props.title}</span>}
            {props.description && <p>{props.description}</p>}
        </div>
    )
}

DonateOption.defaultProps = {
    className: null
}

export default withTheme(supportedThemes)(DonateOption)
